<template>
  <v-app>
    
  </v-app>
</template>

<script>
export default {
  name: 'API',
  components: {
  },
  data() {
    return {
 
    }
  },
  created() {
 
  },
  mounted() {
  },
  
}
</script>

<style lang="scss" scoped>


</style>
